// This is a reducer function that handles the state updates for restaurant list data.
// It takes in the current state and an action object as arguments.

import {
  FETCH_REST_LIST_FAILURE,
  FETCH_REST_LIST_REQUEST,
  FETCH_REST_LIST_RESET,
  FETCH_REST_LIST_SUCCESS,
} from './type';

const initialState = {
  rest: [],
  loading: false,
  error: null,
  more: false,
  search: '',
  sRest: [],
};

export default function restReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REST_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case FETCH_REST_LIST_SUCCESS:
      if (action.payload.search.length) {
        if (action.payload.search === state.search) {
          // Clear the rest state to an empty array when search is true
          return {
            ...state,
            loading: false,
            sRest: [...state.sRest, ...action.payload.restaurant],
            rest: [],
            more: action.payload.hasMore,
            search: action.payload.search,
          };
        } else if (action.payload.search !== state.search) {
          // Clear the sRest state and push new values from action.payload.rest
          return {
            ...state,
            loading: false,
            sRest: [...action.payload.restaurant],
            rest: [],
            more: action.payload.hasMore,
            search: action.payload.search,
          };
        }
      } else {
        return {
          ...state,
          loading: false,
          rest: [...state.rest, ...action.payload.restaurant],
          sRest: [],
          more: action.payload.hasMore,
        };
      }
    case FETCH_REST_LIST_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case FETCH_REST_LIST_RESET:
      return { ...initialState };
    default:
      return state;
  }
}
