//this action defines success ,failure and api request of nearby rest

import {
  FETCH_REST_LIST_FAILURE,
  FETCH_REST_LIST_REQUEST,
  FETCH_REST_LIST_RESET,
  FETCH_REST_LIST_SUCCESS,
} from './type';

export function fetchRestRequest(page, search, longitude, latitude) {
  return {
    type: FETCH_REST_LIST_REQUEST,
    payload: { page, search, longitude, latitude },
  };
}

export function fetchRestSuccess(rest) {
  return {
    type: FETCH_REST_LIST_SUCCESS,
    payload: rest,
  };
}

export function fetchRestFailure(error) {
  return {
    type: FETCH_REST_LIST_FAILURE,
    payload: error,
  };
}

export function fetchRestReset() {
  return {
    type: FETCH_REST_LIST_RESET,
  };
}
